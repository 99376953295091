<template>
  <div>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis">
    </BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: '予約履歴',
      apis: {
        list: {
          endpoint: this.$consts.API.HOTEL.BOOKING_HISTORY_LIST,
          params: {"filters[booking_id][equal]": this.$route.params.id}
        },
      },
      actions: {},
      tools: {
        search: {
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToSave: {},
      entryToEdit: {},
      startPoints: [],
      taxi: {},
      columns: [
        {name: 'owner.name', label: 'アカウント', sortable: false},
        {name: 'created_at', label: '日付', class: 'text-center'},
        {
          name: 'new_booking_status',
          label: this.$t('common.status'),
          class: "text-center",
          computedHtml: entry => {
            if (entry.new_booking_status == this.$consts.BOOKING.STATUS_DRAFT) {
              return `<span class="alert">${this.$consts.BOOKING.STATUS_DRAFT_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_NEW) {
              return `<span class="alert alert-info">${this.$consts.BOOKING.STATUS_NEW_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_ARRANGER) {
              return `<span class="alert alert-warning">${this.$consts.BOOKING.STATUS_ARRANGER_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_UPDATE) {
              return `<span class="alert alert-warning">${this.$consts.BOOKING.STATUS_UPDATE_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_CANCEL_NO_CHARGE) {
              return `<span class="alert alert-disable">${this.$consts.BOOKING.STATUS_CANCEL_NO_CHARGE_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_CANCEL_CHARGE) {
              return `<span class="alert alert-disable">${this.$consts.BOOKING.STATUS_CANCEL_CHARGE_LABEL}</span>`
            } else if (entry.new_booking_status == this.$consts.BOOKING.STATUS_OPERATED) {
              return `<span class="alert alert-success">${this.$consts.BOOKING.STATUS_OPERATED_LABEL}</span>`
            }
          },
          sortable: false
        },
        {
          name: 'update_note',
          label: '変更内容',
          sortable: false,
          class: 'min-w-999',
          computedHtml: entry => entry.update_note ? `<div style="white-space: pre-line">${entry.update_note}</div>` : ''
        },
      ]
    }
  },
  components: {BasicTable},
}
</script>
